<template>
  <v-container fluid>
    <ClientMap :mapData="clients" />
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import ClientMap from "../../components/ClientMap.vue";
export default {
  components: { ClientMap },
  data() {
    return {
      errors: [],
      loading_status: false,
      clients: [],
      table: null,
    };
  },
  mounted() {
    console.log(this.$route.params);
    if (this.$route.params.table) {
      this.table = this.$route.params.table;
      this.get_clients();
    }
  },
  methods: {
    get_clients() {
      this.loading_status = true;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          { field: "location", operator: "<>", value: "" },
          { field: "account", operator: "=", value: this.$store.state.profile.account },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.clients = data;
      });
    },
  },
};
</script>
